import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "src/layout/layout"
import SEO from "src/components/seo"
import Hero from "src/components/hero"
import SubsectionImgLeftLink from "src/components/subsection-img-left-link"
import SubsectionImgRightLink from "src/components/subsection-img-right-link"
import CTA from "src/components/cta"
import Spacer from "src/components/spacer"
import { Typography } from "@material-ui/core"
import homeContent from "src/locales/en/home.json"

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "120px",
  },
  header: {
    width: "50%",
    margin: "48px",
    "@media (max-width:768px)": {
      width: "90%",
    },
  },
})

export default function IndexPage({ location }) {
  const data = useStaticQuery(graphql`
    query IndexPageContent {
      heroImage: file(relativePath: { eq: "home/index_hero.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sectionImageAbout: file(
        relativePath: { eq: "home/about-dp-aerogels-eco.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sectionImageEcoAerogels: file(
        relativePath: { eq: "home/about-dp-aerogels-microscope.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const classes = useStyles()
  const heroImage = data.heroImage.childImageSharp.fluid
  const sectionImageAbout = data.sectionImageAbout.childImageSharp.fluid
  const sectionImageEcoAerogels =
    data.sectionImageEcoAerogels.childImageSharp.fluid

  return (
    <>
      <Layout location={location}>
      <SEO title={homeContent.seo.title} />
        <Hero
          imageData={heroImage}
          imageAlt={homeContent.hero.imgAlt}
          textTitle={homeContent.hero.heading}
          textDescription={homeContent.hero.description}
          dark={true}
        />
        <div className={classes.headerContainer}>
          <Typography className={classes.header} variant="body1">
            {homeContent.sectionText.description}
          </Typography>
        </div>
        <SubsectionImgLeftLink
          imageData={sectionImageAbout}
          imageAlt={""}
          textTitle={homeContent.subsectionImgLeft.heading}
          textDescription={homeContent.subsectionImgLeft.description}
          linkLabel={homeContent.subsectionImgLeft.linkLabel}
          linkUrl={homeContent.subsectionImgLeft.linkUrl}
        />
        <Spacer />
        <SubsectionImgRightLink
          imageData={sectionImageEcoAerogels}
          imageAlt={""}
          textTitle={homeContent.subsectionImgRight.heading}
          textDescription={homeContent.subsectionImgRight.description}
          linkLabel={homeContent.subsectionImgRight.linkLabel}
          linkUrl={homeContent.subsectionImgRight.linkUrl}
        />
        <Spacer />
        <CTA />
      </Layout>
    </>
  )
}
